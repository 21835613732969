import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSubscribeNewsletter } from '../../services/EmailPreference/emailPreferenceService';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NotificationManager } from 'react-notifications';
import * as countryZones from './country-timezone.json';

type NewsLetterSubscriber = { email: string };

const newsLetterSubscriberSchema = yup
  .object()
  .shape({ email: yup.string().email().required() })
  .required();

function NewsLetter() {
  const { t } = useTranslation();
  const { mutateAsync: subscribeNewsletter } = useSubscribeNewsletter();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<NewsLetterSubscriber>({
    mode: 'all',
    defaultValues: {},
    resolver: yupResolver<NewsLetterSubscriber>(newsLetterSubscriberSchema),
  });

  const onSubmit = async (data: any) => {
    console.log(data);
    try {
      const { countries, zones } = countryZones as any;
      const timeZoneCityToCountry = {} as any;
      Object.keys(zones).forEach(z => {
        const cityArr = z.split("/");
        const city = cityArr[cityArr.length-1];
        timeZoneCityToCountry[city] = countries[zones[z].countries[0]].name;
      })
      let userCountry;
      let userCity;
      if (Intl) {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const tzArr = userTimeZone.split('/');
        userCity = tzArr[tzArr.length - 1];
        userCountry = timeZoneCityToCountry[userCity];
      }
      //  console.log('Country:', userCountry);
      await subscribeNewsletter({ ...data, send_newsletters: true, country: userCountry || userCity });
      NotificationManager.success(t('Thanks for subscribing our Newsletter.'));
    } catch (e) {
      console.log(e);
      NotificationManager.error(t('Something went wrong! Please try again.'));
    }
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ul className="list-unstyled list-inline text-center py-2">
        <li className="list-inline-item tothabottom">
          <h5 className="mb-1">
            {t('Subscribe.btn')} {t('for Newsletter')}
          </h5>
        </li>
        <li className="list-inline-item">
          <input
            {...register('email')}
            className="form-control"
            placeholder={t('Your email address')}
          />
          {errors.email && errors.email.message && (
            <span className="text-danger text-start">
              {t(errors.email.message).replace('{min}', '8')}
            </span>
          )}
        </li>
        <li className="list-inline-item">
          <button className="btn btn-gold btn-sm" type="submit">
            {t('Subscribe.btn')}
          </button>
        </li>
      </ul>
    </form>
  );
}

export default NewsLetter;
