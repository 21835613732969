/* eslint-disable react-hooks/exhaustive-deps */
import { createColumnHelper, PaginationState } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';
import { confirmBox } from '../../components/ConfirmBox';
import Datatable from '../../components/Datatable';
import { useContainerContext } from '../../context/ContainerContext';
import { TripDetails } from '../../services/Guide/guide';
import useTripHook from '../../services/Trip/tripService';
import { getPrice, getSession } from '../../services/utils';
import TripInfoModel from '../Guides/components/Trips/tripInfoModel';

const columnHelper = createColumnHelper<TripDetails>();

function Trips() {
  const { t } = useTranslation();
  const { state } = useContainerContext();
  const navigate = useNavigate();
  const [viewModel, setViewModel] = useState<{
    show: boolean;
    id?: number;
  }>({ show: false });

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const fetchDataOptions = {
    pageIndex,
    pageSize,
    guideId: state.guideId,
  };

  const { getTripsByGuideId, deleteTrip } = useTripHook(fetchDataOptions);

  const { isSuccess: isSuccessDelete, mutateAsync: mutateAsyncDelete } = deleteTrip;

  useEffect(() => {
    if (isSuccessDelete) {
      getTripsByGuideId.refetch();
      NotificationManager.success(t('notification.trip.deleted'));
    }
  }, [isSuccessDelete]);

  const columns = [
    columnHelper.accessor((row) => row?.name, {
      id: 'name',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <span className="text-capitalize">{t('common.name')} </span>,
    }),
    columnHelper.accessor(
      (row) =>
        getSession(t, row?.operateDays ? row?.operateDays : [], row?.isSeasonalTrip, row?.season),
      {
        id: 'SEASON',
        cell: (info) => <i>{info.getValue()}</i>,
        header: () => <span className="text-capitalize">{t('trip.season')} </span>,
      }
    ),
    columnHelper.accessor(
      (row) =>
        getPrice(
          t,
          row?.isSharedTrip,
          row?.isSharedTrip === true ? row?.pricePerPerson : row?.priceifNotShared
        ),
      {
        id: 'PRICE',
        cell: (info) => <i>{info.getValue()}</i>,
        header: () => <span className="text-capitalize">{t('trip.price')} </span>,
      }
    ),

    columnHelper.display({
      id: 'actions',
      cell: (props) => (
        <div className="text-right">
          <a
            href="#copy"
            className="text-capitalize"
            onClick={() => {
              navigate(`/trip/create/${props.row.original.id}`);
            }}
          >
            {t('common.copy')}
          </a>
          {' | '}
          <a
            href="#edit"
            onClick={() => {
              navigate(`/trip/update/${props.row.original.id}`);
            }}
          >
            {t('common.edit')}
          </a>
          {' | '}
          <a
            href="#delete"
            onClick={() => {
              confirmBox({
                title: 'Delete Trip',
                message: 'Are you sure you want to delete this?',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => mutateAsyncDelete(Number(props.row.original.id)),
                  },
                  {
                    label: 'No',
                  },
                ],
              });
            }}
          >
            {t('common.delete')}
          </a>
          {' | '}
          <a
            href="#view"
            onClick={() => setViewModel({ show: true, id: Number(props.row.original.id) })}
          >
            {t('common.view')}
          </a>
        </div>
      ),
      header: () => <div className="text-right">{t('common.action')}</div>,
    }),
  ];

  return (
    <div className="content">
      <div className="row">
        <div className="col-md-12">
          <div className="card card-plain">
            <div className="card-header">
              <h5 className="card-title">{t('nav.myTrips')}</h5>
              <div className="card-category" style={{ float: 'right' }}>
                <button
                  type="button"
                  className="btn btn-gold btn-circle btn-xl"
                  onClick={() => {
                    navigate('/trip/create');
                  }}
                >
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <Datatable
                dataQuery={getTripsByGuideId}
                tableColumns={columns}
                pagination={pagination}
                onPaginationChange={setPagination}
              />
            </div>
          </div>
        </div>
      </div>
      {viewModel.show && viewModel.id && (
        <TripInfoModel
          show={viewModel.show}
          id={Number(viewModel?.id)}
          onClose={() => setViewModel({ show: false })}
        />
      )}
    </div>
  );
}

export default Trips;
